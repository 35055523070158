@font-face {
    font-family: 'Montserrat-Bold';
    src: url('fonts/Montserrat-Bold.ttf');//font-weight: 700
}
@font-face {
    font-family: 'Montserrat-Medium';
    src: url('fonts/Montserrat-Medium.ttf');//font-weight: 500
}
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('fonts/Montserrat-Regular.ttf');//font-weight: 400
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('fonts/Montserrat-SemiBold.ttf');//font-weight: 600
}
@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url('fonts/Montserrat-SemiBoldItalic.ttf');//font-weight: 600
}