/* basic */
:root {
  --color_1: #5A157C;
  --color_2: #050000;
  --color_3: #f4f4f4;
  --color_4: #b3fd60;
}
.wrapper {
  min-height: 100%;
  overflow: hidden;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
}
._tech1 {
  background-color: #ffffff;
  height: 100%;
}
._tech2 {
  background-color: #919090;
  height: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
ul,
li,
p,
button,
a,
section {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: #eb2c2c;
}
h1 {
  font-size: 35px;
}
body {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 22px;
}
.section-title {
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}
/* nav ================================================================================================= nav */
nav {
  position: relative;
  background-color: var(--color_1);
  height: 80px;
}
nav > div {
  height: 100%;
}
.topmenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  height: 100%;
}
.topmenu-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  margin: 0 10px;
}
._social a:first-child {
  margin-left: 0;
}
._social a {
  margin-left: 0.8rem;
}
._social img {
  width: 30px;
  height: auto;
}
.topmenu_logo,
.topmenu_contact {
  line-height: 160%;
  margin: 0 10px;
}
.topmenu_logo-name {
  font-size: 22px;
}
.topmenu_contact {
  text-align: right;
}
.topmenu_contact-call {
  text-decoration: underline;
}
.menu-triger {
  background: url(../img/menu_white.png) 0 0 repeat;
  display: none;
  width: 25px;
  height: 17px;
  position: absolute;
  right: 15px;
  top: 28px;
}
.menu-close {
  background: url(../img/close_white.png) 0 0 repeat;
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100;
}
.menu-popup {
  background: #2c2e3e;
  display: none;
  position: absolute;
  right: -110%;
  top: 0;
  width: 250px;
  box-sizing: border-box;
  padding: 30px;
  z-index: 9999;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.menu-popup ul {
  margin: 0;
  padding: 0;
}
.menu-popup li {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
.menu-popup li a {
  font-size: 18px;
  font-family: "Arial";
  color: #ffffff;
  line-height: 1.4;
  text-decoration: none;
}
.body_pointer * {
  cursor: pointer;
}
/* header ============================================================================================= header */
header {
  position: relative;
  height: 700px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(../img/matrix.jpg);
  background-size: cover;
  background-repeat: repeat-x;
  background-position: top left;
  color: #fff;
}
.headerpos {
  position: relative;
}
.offer1 {
  margin-left: 30px;
  padding-top: 20px;
}
.printedtext {
  margin-left: 30px;
  margin-top: 10px;
}
.hidden {
  opacity: 0;
}
.console-container {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: block;
}
.console-underscore {
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}
.offer2 {
  margin-left: 30px;
  margin-top: 10px;
}
.headerimg {
  position: absolute;
  right: 30px;
  top: 60px;
  width: 590px;
  height: 380px;
}
.headerimg-img {
  display: block;
  width: 100%;
  height: auto;
}
.offer3 {
  position: absolute;
  right: 30px;
  top: 500px;
  line-height: 140%;
  font-size: 30px;
  text-align: right;
}
/* form1 in header =============================================================================== form1 in header */
.contactform {
  position: absolute;
  top: 180px;
  left: 30px;
  max-width: 500px;
  background-color: #FDEED1;
  border-radius: 20px;
}
form {
  color: #0d4f77;
}
.servicelist {
  margin: 20px 0 20px;
}
._privacy {
  color: inherit;
  text-decoration: underline;
}
/* form3 popup ====================================================================================== form3 popup */
.custom-control-label {
  font-size: 0.9rem;
}
/* sites ================================================================================================== sites */
.sites {
  position: relative;
  background: url(../img/topleftcorner.png) top left no-repeat, url(../img/toprightcorner.png) top right no-repeat;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.sites_grid {
  margin: 20px 0 30px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.sites_grid-item {
  padding: 20px 0 0 20px;
  background-color: var(--color_1);
  width: 380px;
  height: 190px;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 160%;
  position: relative;
}
.sites_grid-item-label {
  position: absolute;
  right: 15px;
  top: -10px;
  width: 120px;
  height: auto;
  border-radius: 8px;
  text-align: center;
  color: #000;
  background-color: var(--color_4);
  z-index: 500;
  -webkit-box-shadow: 0px 0px 0px 5px #A0A0A0, inset 0px 10px 27px -8px #141414, inset 0px -10px 27px -8px #A31925, 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 5px #A0A0A0, inset 0px 10px 27px -8px #141414, inset 0px -10px 27px -8px #A31925, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
.sites_grid-item-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
#landing,
#corporat {
  justify-self: end;
}
._animleft {
  justify-self: end;
  position: relative;
  animation: showLeft 1s;
}
._animright {
  justify-self: start;
  position: relative;
  animation: showRight 1s;
}
@keyframes showLeft {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes showRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
.sites_img {
  height: 300px;
  position: relative;
  margin: -60px auto 0px;
  max-width: 1020px;
}
.sites_img-left {
  position: absolute;
  left: 0px;
  height: 100%;
}
.sites_img-ltext {
  position: absolute;
  left: 15rem;
  top: 3rem;
}
.sites_img-rtext {
  position: absolute;
  right: 12rem;
  bottom: 0.6rem;
}
.sites_img-right {
  position: absolute;
  right: 0px;
  height: 100%;
}
.sites_img-img {
  height: 100%;
}
.sites_process {
  background: linear-gradient(to bottom, #5a157c, rgba(90, 21, 124, 0.05));
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
}
.sites_process-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  column-gap: 2vw;
  row-gap: 1vw;
  margin: 30px 40px;
}
.sites_process-grid-item {
  background-image: url(../img/sticky_head.png), url(../img/sticky_body.png);
  background-position: center top, center;
  background-repeat: no-repeat, repeat-y;
  background-size: contain;
  font-size: 16px;
  padding: 60px 20px 20px 20px;
  text-align: justify;
}
.sites_process-grid-item > div::first-letter {
  float: left;
  line-height: 35px;
  font-size: 55px;
  font-family: Montserrat;
  color: #7D3C98;
  border: solid #D5C5EC;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}
._grid-item-first {
  line-height: 26px;
}
._grid-item-second {
  line-height: 30px;
}
._grid-item-third {
  line-height: 26px;
}
._grid-item-fourth {
  line-height: 27px;
}
._grid-item-fifth {
  line-height: 30px;
}
#grid-item-fifth {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-column-end: 3;
  grid-row-end: 3;
}
._grid-item-sixth {
  line-height: 26px;
}
#grid-item-sixth {
  grid-column-start: 3;
  grid-row-start: 2;
  grid-column-end: 4;
  grid-row-end: 3;
}
.sites_process-text {
  max-width: 800px;
  margin: 0 auto 30px;
  text-align: center;
}
/* whyme ============================================================================================= whyme */
.whyme {
  margin: 20px 0 30px;
}
.whyme_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 40px;
}
.whyme_grid-item {
  background-color: #eeeeee;
  -webkit-box-shadow: 0px 0px 7px 1px #000000;
  box-shadow: 0px 0px 7px 1px #000000;
}
.whyme_grid-item-icon {
  width: 70px;
  height: 70px;
  margin: 10px auto 0px;
}
.icon-maket {
  background: url(../img/maket.png);
  background-size: cover;
}
.whyme_grid-item-desc {
  margin-top: 15px;
  padding: 0px 30px 30px 30px;
  font-size: 20px;
}
.whyme_grid-item-desc::first-letter {
  float: left;
  line-height: 35px;
  font-size: 45px;
  font-family: Montserrat;
  color: var(--color_1);
  border: solid #ba9fdf;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.icon-html {
  background: url(../img/html.png);
  background-size: cover;
}
.icon-semantic {
  background: url(../img/semantika.png);
  background-size: cover;
}
.icon-layout {
  background: url(../img/construct.png);
  background-size: cover;
}
.icon-sitecode {
  background: url(../img/code.png);
  background-size: cover;
}
.icon-sertificat {
  background: url(../img/direct.png);
  background-size: cover;
}
/* slideshowofers ===================================================================================== slideshowofers */
.slideshowoffers {
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/foto2_1.jpg) top center repeat-x;
  background-size: cover;
  height: 300px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  border: 10px solid #fff;
}
.owl-carousel {
  margin-top: 50px;
}
.owl-next {
  margin-left: 40px;
}
.owl-nav {
  margin-top: 20px;
}
.owl-carousel-item > img {
  width: 80px;
  height: auto;
  margin: 0 auto 10px;
}
.owl-carousel-item {
  text-align: center;
}
/* context ========================================================================================== context */
#context {
  position: relative;
  background: url(../img/foncontext.jpg);
  background-size: cover;
  background-position: center bottom;
  color: #fff;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
#context h1 {
  margin-top: 50px;
}
#context h1.section-title {
  margin-left: 40px;
  margin-right: 40px;
}
.context_main {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "contextform contextoffers";
}
.context_form {
  grid-area: contextform;
}
.context_offer {
  grid-area: contextoffers;
}
.getfreecons {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
}
.context_main .form-group {
  width: 500px;
  margin-top: 10px;
}
.context_main .form-row {
  align-items: center;
}
.form-control-context {
  height: 50px;
  border-radius: 10px;
}
.context-submit button {
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 22px;
  width: 490px;
}
.context_offer-item {
  display: flex;
  align-items: center;
}
.context_offer-item:not(:first-child) {
  margin-top: 40px;
}
.context_icon {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  background-size: cover;
  flex-shrink: 0;
}
.context-sale {
  background-image: url(../img/sale.png);
}
.context-go {
  background-image: url(../img/2day.png);
  width: 80px;
  height: 80px;
  margin-left: -10px;
}
.context-free {
  background-image: url(../img/free1.png);
}
.context_sert {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "sert price";
  margin: 30px 20px 40px;
}
.context_sert-sert {
  grid-area: sert;
  display: flex;
  align-items: center;
  margin-right: 30px;
  justify-self: right;
  max-width: 485px;
}
.context_sert-sert-icon {
  background-image: url(../img/sertificat.jpg);
  background-size: cover;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  flex-shrink: 0;
}
.context_sert-price {
  grid-area: price;
  display: flex;
  align-items: center;
  background-color: var(--color_1);
  border-radius: 10px;
  max-width: 485px;
  margin-left: 30px;
}
.context_sert-price-icon {
  background-image: url(../img/bestprice.png);
  background-size: cover;
  width: 70px;
  height: 70px;
  margin: 0 20px;
  flex-shrink: 0;
}
.context_sert-price-text {
  line-height: 150%;
}
.context_sert-price-text span {
  text-decoration: line-through;
}
/* settingcontext ============================================================================ settingcontext */
#setcontext {
  background-color: var(--color_3);
  border-top: 1px solid transparent;
}
.setcontext_grid {
  margin: 20px 20px 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.setcontext_grid-text {
  line-height: 180%;
  text-align: justify;
  padding: 0 30px;
}
.setcontext_grid-text img {
  display: block;
  margin: 0 auto;
}
.setcontext_grid-img > img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
/* advantage ============================================================================== advantage */
#advantage {
  background: url(../img/fon_advantage.jpg);
  height: auto;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
}
#advantage h1 {
  color: #fff;
}
.advantage_grid {
  margin: 20px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1vw;
}
.advantage_grid-item {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: justify;
}
.advantage_grid-item:hover {
  background-color: var(--color_1);
  color: #fff;
}
.advantage_grid-item img {
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
/* settinglist ============================================================================== settinglist */
#settinglist {
  background-color: var(--color_3);
  border-top: 1px solid transparent;
  box-sizing: border-box;
}
.bvambient_particle {
  position: absolute;
  pointer-events: none;
  transition: top linear, left linear;
}
.settinglist_wrap-item {
  display: flex;
  align-items: center;
  background-color: var(--color_1);
  color: #fff;
  font-size: 16px;
  padding: 20px;
  max-width: 550px;
  border-radius: 10px;
  margin: 10px auto;
}
.settinglist_wrap-item div {
  z-index: 10;
}
.settinglist_wrap-item span {
  font-size: 22px;
}
.settinglist_wrap-item img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
/* seo ========================================================================================== seo */
.seo-subtitle {
  font-size: 22px;
}
.seo_des {
  font-size: 18px;
  text-align: justify;
  line-height: 25px;
  margin-top: 10px;
}
.seo_des > span {
  font-weight: 600;
}
.seo_des::first-letter {
  float: left;
  line-height: 35px;
  font-size: 55px;
  font-family: Montserrat;
  color: #7D3C98;
  border: solid #D5C5EC;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.seo-tab {
  display: flex;
  margin: 20px auto;
}
.seo-tab-item {
  margin-right: 1.875rem;
}
.seo-tab-item:last-child {
  margin-right: 0;
}
.seo-tab-item-title {
  font-size: 1.375rem;
  text-align: center;
}
.seo-tab-item-des {
  font-size: 1rem;
  text-align: justify;
  margin-top: 10px;
  line-height: 25px;
}
/* about Me ============================================================================ about Me */
#aboutme {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)), url(../img/aboutme.jpg) center center repeat-x;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  color: #ffffff;
}
.aboutme {
  max-width: 800px;
  font-size: 18px;
  margin: 20px auto 20px;
  line-height: 160%;
}
.aboutme-caption {
  text-align: center;
  font-size: 22px;
}
.aboutme-text {
  text-align: justify;
}
.aboutme-img {
  text-align: center;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
}
.aboutme-img img {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: auto;
}
/* contact-form-bottom ========================================================== contact-form-bottom */
#contact-form-bottom {
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
}
._formbottom {
  max-width: 600px;
  margin: 0 auto;
}
/* footer ============================================================================== footer*/
footer {
  position: relative;
  background-color: var(--color_1);
  height: 80px;
}
footer > div {
  height: 100%;
}
.footermenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  height: 100%;
}
.footermenu-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  margin: 0 10px;
}
._social a:first-child {
  margin-left: 0;
}
._social a {
  margin-left: 0.8rem;
}
._social img {
  width: 30px;
  height: auto;
}
.footermenu_logo,
.footermenu_contact {
  line-height: 160%;
  margin: 0 10px;
}
.footermenu_logo-name {
  font-size: 22px;
}
.footermenu_contact {
  text-align: right;
}
.footermenu_contact-call {
  text-decoration: underline;
}
/* max-width: 1280px ================================================================= max-width: 1280px */
@media screen and (max-width: 1280px) {
  #seo {
    margin-left: 20px;
    margin-right: 20px;
  }
  .whyme {
    margin: 20px 30px 30px;
  }
}
/* max-width: 1160px ================================================================= max-width: 1160px */
@media screen and (max-width: 1160px) {
  .topmenu {
    font-size: 16px;
  }
  .offer3 {
    font-size: 28px;
  }
  .headerimg {
    top: 40%;
    width: 50%;
    height: auto;
  }
  .contactform {
    width: 40%;
  }
  .slideshowoffers {
    font-size: 22px;
    height: 250px;
    overflow: hidden;
  }
  .setcontext_grid-text {
    font-size: 20px;
  }
  #grid-item-fifth {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-start: auto;
    grid-row-end: auto;
  }
  #grid-item-sixth {
    grid-column-start: auto;
    grid-row-start: auto;
    grid-column-end: auto;
    grid-row-end: auto;
  }
  .sites_process-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 2vw;
  }
  .sites_process-grid-item {
    padding: 70px 20px 20px 20px;
    max-width: 300px;
  }
  .context_main .form-group {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .context-submit button {
    width: 100%;
  }
}
/* max-width: 970px ================================================================= max-width: 970px */
@media screen and (max-width: 970px) {
  .topmenu_logo-name {
    font-size: 24px;
  }
  ._social {
    display: none;
  }
  .offer1 {
    font-size: 30px;
    margin-left: 0;
  }
  .printedtext {
    margin-left: 0;
  }
  .console-container {
    font-size: 26px;
  }
  .offer2 {
    font-size: 20px;
    margin-left: 0;
  }
  .headerimg {
    top: 200px;
    width: 45%;
    height: auto;
  }
  .offer3 {
    font-size: 22px;
  }
  .contactform {
    width: 45%;
  }
  .offer_pos {
    text-align: center;
  }
  .whyme_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .whyme_grid-item-icon {
    width: 60px;
    height: 60px;
  }
  .context_offer-item:not(:first-child) {
    margin-top: 55px;
  }
  #context h1 {
    font-size: 35px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .context_sert {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "price" "sert";
  }
  .context_sert-price {
    text-align: center;
    justify-self: center;
    margin-left: 0px;
    width: 80%;
  }
  .context_sert-sert {
    margin-top: 20px;
    text-align: center;
    justify-self: center;
    margin-right: 0px;
    width: 80%;
  }
  h1 {
    font-size: 30px;
  }
  .sites_grid-item {
    font-size: 16px;
    padding: 20px;
    height: auto;
  }
  .sites_grid-item-title {
    font-size: 20px;
  }
  .sites_img {
    height: 250px;
  }
  .sites_img-ltext {
    top: 2.4rem;
    font-size: 1.1rem;
  }
  .sites_img-rtext {
    font-size: 1.1rem;
    right: 10rem;
  }
  .setcontext_grid-text {
    font-size: 18px;
    padding: 0;
    line-height: 150%;
  }
  .advantage_grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
  .advantage_grid-item {
    font-size: 14px;
  }
  .settinglist_wrap {
    margin: 0 20px;
  }
  .aboutme-caption {
    font-size: 20px;
  }
}
/* max-width: 850px ================================================================= max-width: 850px */
@media screen and (max-width: 850px) {
  .offer1 {
    font-size: 26px;
    margin-left: 0;
  }
  .console-container {
    font-size: 24px;
  }
  .topmenu-items {
    display: none;
  }
  .menu-popup {
    display: block;
  }
  .menu-triger {
    display: block;
    z-index: 500;
  }
  header {
    height: auto;
  }
  .headerimg {
    display: none;
  }
  .topmenu {
    font-size: 14px;
  }
  .contactform {
    width: auto;
    top: 0;
    left: 0;
    position: relative;
    margin: 20px auto;
  }
  .offer3 {
    position: relative;
    top: 0;
    right: 0;
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;
  }
  .form-row h4 {
    font-size: 18px;
  }
  .servicelist {
    font-size: 16px;
  }
  .form__wrapper {
    margin-bottom: 0;
  }
  .sites_grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .sites_img {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: -20px auto 0;
  }
  .sites_img div {
    position: relative;
  }
  .sites_img-img {
    height: 150px;
  }
  .sites_img-left {
    margin: 0 auto 10px;
  }
  .sites_img-ltext {
    order: -1;
    top: 0;
    left: 0;
    text-align: center;
  }
  .sites_img-right {
    margin: 0 auto;
  }
  .sites_img-rtext {
    order: 2;
    right: 0;
    bottom: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  #landing,
  #visit,
  #corporat,
  #intmag {
    justify-self: center;
  }
  h1 {
    font-size: 24px;
  }
  .whyme {
    margin-left: 15px;
    margin-right: 15px;
  }
  .slideshowoffers {
    font-size: 18px;
    height: 200px;
  }
  .owl-carousel {
    margin-top: 20px;
  }
  .context_main {
    grid-template-areas: "contextoffers" "contextform";
    align-items: center;
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
  .context_offer {
    padding-right: 125px;
    padding-left: 125px;
  }
  .context_form {
    margin-top: 30px;
  }
  .context_offer-item:not(:first-child) {
    margin-top: 20px;
  }
  .context_main .form-group {
    width: 75%;
  }
  .context_sert {
    margin-top: 20px;
  }
  .setcontext_grid-text {
    font-size: 16px;
  }
  .setcontext_grid {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .setcontext_grid-img {
    height: 200px;
  }
  .setcontext_grid-img img {
    height: 100%;
    width: auto;
  }
  #advantage {
    margin-top: 20px;
  }
  .advantage_grid-item {
    padding: 10px;
  }
  .settinglist_wrap-item span {
    font-size: 20px;
  }
  .settinglist_wrap-item {
    font-size: 14px;
  }
  .aboutme-img img {
    max-width: 180px;
  }
  .aboutme {
    margin: 20px 20px;
  }
  .sites_process-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .seo-subtitle {
    text-align: center;
  }
  .seo-tab {
    flex-direction: column;
  }
  .seo-tab-item:first-child {
    margin-top: 0;
  }
  .seo-tab-item {
    margin-top: 20px;
    margin-right: 0;
  }
  .seo-tab-item-des {
    text-align: center;
  }
}
/* max-width: 768px ================================================================= max-width: 768px */
@media screen and (max-width: 768px) {
  .context_offer {
    margin-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
  }
  .context_main .form-group {
    width: 85%;
  }
  .whyme_grid-item-desc::first-letter {
    font-size: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
/* max-width: 550px ================================================================= max-width: 550px */
@media screen and (max-width: 550px) {
  .topmenu {
    display: block;
    text-align: center;
    border-top: 1px solid transparent;
  }
  .topmenu_logo-des {
    display: none;
  }
  .topmenu_contact {
    text-align: center;
  }
  .contactform {
    margin: 20px 30px;
  }
  .offer1 {
    font-size: 24px;
  }
  .console-container {
    font-size: 20px;
  }
  .offer2 {
    font-size: 16px;
  }
  .offer3 {
    font-size: 16px;
  }
  .topmenu_logo {
    margin-top: 10px;
  }
  .sites_grid {
    margin: 15px 30px 25px;
  }
  .sites_grid-item {
    width: auto;
  }
  .whyme_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-top: 20px;
  }
  .context_main .form-group {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #context h1 {
    font-size: 28px;
  }
  #context h1.section-title {
    margin-left: 0px;
    margin-right: 0px;
  }
  .context_icon {
    width: 60px;
    height: 60px;
  }
  .context-go {
    width: 70px;
    height: 70px;
  }
  .context_offer {
    font-size: 20px;
  }
  .context-submit button {
    font-size: 20px;
  }
  .advantage_grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
  .advantage_grid-item {
    font-size: 14px;
  }
  .aboutme-img {
    float: none;
  }
  .aboutme-caption {
    font-size: 18px;
  }
  .aboutme-text {
    font-size: 16px;
  }
  .sites_process-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .sites_process-grid-item {
    max-width: 280px;
    font-size: 0.875rem;
  }
  .process-grid-des {
    line-height: 150%;
  }
  .seo-tab {
    align-items: center;
  }
  .seo-tab-item {
    max-width: 300px;
  }
}
/* max-width: 480px ================================================================= max-width: 480px */
@media screen and (max-width: 480px) {
  .context_sert-price-text {
    font-size: 18px;
  }
  .context_sert-sert-text {
    font-size: 18px;
  }
  .whyme_grid-item-desc {
    font-size: 16px;
  }
  .whyme_grid-item-icon {
    width: 50px;
    height: 50px;
  }
}
/* max-width: 370px ================================================================= max-width: 370px */
@media screen and (max-width: 370px) {
  #context h1.section-title {
    font-size: 22px;
  }
  .context_icon {
    width: 40px;
    height: 40px;
  }
  .context-go {
    width: 50px;
    height: 50px;
  }
  .context_offer {
    font-size: 18px;
  }
  .getfreecons {
    font-size: 20px;
  }
  .context_sert-price {
    width: 95%;
  }
  .context_sert-sert {
    width: 95%;
  }
}
/*--------------------------------------------------------------------------------------------------------------------------------*/
.custom-file-label {
  overflow: hidden;
}
.custom-file-label::after {
  content: "Найти";
}
.panel-success {
  border-color: #29b74e;
}
.panel-success > .panel-heading {
  color: #fff;
  background-color: #2ecc71;
  border-color: #29b74e;
}
.btn-success {
  color: #fff;
  background-color: #2ecc71;
  border-color: #29b765;
}
.success {
  color: #29b74e;
}
.jgrowl-error {
  background-color: #a94442 !important;
}
.jgrowl-success {
  background-color: #3c763d !important;
}
.jgrowl-warning {
  background-color: #8a6d3b !important;
}
